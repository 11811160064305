import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import Articles from '../../components/Articles/Articles';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const Separator = makeShortcode("Separator");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`ADT im FVDH`}</h1>
      <p>{`Sehr geehrte Kolleginnen und Kollegen, liebe Besucher unserer Website,`}</p>
      <p>{`im Namen des Vorstandes, des Präsidiums sowie der Landesleiter der ADT im FVDH möchten wir Sie auf unseren Internetseiten herzlich willkommen heißen.`}</p>
      <p><a parentName="p" {...{
          "href": "/adt/allgemeine-informationen"
        }}>{`Weitere Informationen`}</a></p>
      <Separator mdxType="Separator" />
      <Articles category='adt-news' mdxType="Articles" />
    </Wrapper>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      